<!-- eslint-disable vue/no-parsing-error -->
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-card class="py-5">
      <v-card-title>
        {{ this.$route.name === 'salesForm' ? $t('actions.makeSale') : $t('actions.return') }}
      </v-card-title>
      <template>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="submitForm()"
        >
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                <v-col
                  cols="12"
                  class="d-flex"
                >
                  <!-- <v-text-field
                    v-if="this.$route.params.id"
                    v-model="data.label"
                    required
                    :label="$t('filter.label')"
                    disabled
                    dense
                    outlined
                  /> -->
                  <v-combobox
                    v-model="choosenProduct"
                    :items="products"
                    dense
                    outlined
                    :loading="productLoading"
                    item-text="label"
                    item-value="id"
                    :label="$t('filter.label')"
                    @keypress="getProducts"
                  />
                  <v-btn
                    color="success"
                    @click="addItem(choosenProduct)"
                  >
                    add
                  </v-btn>
                </v-col>
                <v-col
                  v-if="data.products.length > 0"
                  cols="12"
                >
                  <v-card>
                    <v-data-table
                      :headers="productsHeaders"
                      :items="data.products"
                      :single-expand="singleExpand"
                      :expanded.sync="expanded"
                      item-key="label"
                      show-expand
                      class="elevation-1"
                    >
                    <template v-slot:[`item.image`]="{ item }">
                      <v-img :src="'https://api.newturquoisejewelry.com/img/products/'+item.image"></v-img>
                    </template>
                    >
                    <template v-slot:[`item.stones`]="{ item }">
                      {{item.stones.length}}
                    </template>
                    <template v-slot:[`item.item_total_after_profit`]="{ item }">
                      {{ Math.ceil(item.item_total_after_profit * optionData.dollar_price) }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <!-- <v-tooltip
                        v-if="item.stones.length > 0"
                        bottom
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-btn
                            x-small
                            fab
                            outlined
                            class="mx-2"
                            color="blue"
                            v-bind="attrs"
                            v-on="on"
                            @click="viewStoneDialog(item)"
                          >
                            <v-icon>
                              mdi-eye
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t('actions.viewStones') }}
                      </v-tooltip> -->
                      <v-tooltip
                        bottom
                      >
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <v-btn
                            x-small
                            fab
                            outlined
                            class="mx-2"
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            @click="confirmDeleteItem(item)"
                          >
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t('actions.delete') }}
                      </v-tooltip>
                    </template>
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>products Table</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">
                                    Label
                                  </th>
                                  <th class="text-left">
                                    Qty
                                  </th>
                                  <th class="text-left">
                                    Weight
                                  </th>
                                  <!-- <th class="text-left">
                                    Setting
                                  </th> -->
                                  <th class="text-left">
                                    Color
                                  </th>
                                  <th class="text-left">
                                    Clarity
                                  </th>
                                  <th class="text-left">
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(stone) in item.stones"
                                  :key="stone.id"
                                >
                                  <td>
                                    {{ stone.code }}
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="stone.quantity"
                                      required
                                      dense
                                      outlined
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="stone.weight"
                                      required
                                      dense
                                      outlined
                                    />
                                  </td>
                                  <!-- <td>
                                    <v-text-field
                                      v-model="item.setting"
                                      required
                                      dense
                                      outlined
                                    />
                                  </td> -->
                                  <td>
                                    <v-text-field
                                      v-model="stone.color"
                                      required
                                      dense
                                      outlined
                                    />
                                  </td>
                                  <td>
                                    <v-text-field
                                      v-model="stone.clarity"
                                      required
                                      dense
                                      outlined
                                    />
                                  </td>
                                  <td>
                                    {{ stone.total = (+stone.price * +stone.weight) + (+stone.quantity) }}
                                    <!-- * +item.setting -->
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                        </v-simple-table>
                        </td>
                        <!-- <td :colspan="headers.length">
                          More info about {{ item.label }}
                        </td> -->
                      </template>
                    </v-data-table>
                    <!-- <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">
                              Image
                            </th>
                            <th class="text-center">
                              Product Code
                            </th>
                            <th class="text-center">
                              Weight
                            </th>
                            <th class="text-center">
                              QTY
                            </th>
                            <th class="text-center">
                              Total in EGP
                            </th>
                            <th class="text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item) in data.products"
                            :key="item.id"
                          >
                            <td class="text-center">
                              <v-img
                                width="70"
                                height="70"
                                :src="'https://api.newturquoisejewelry.com/img/products/'+ item.image"
                              />
                            </td>
                            <td class="text-center">
                              {{ item.label }}
                            </td>
                            <td class="text-center">
                              {{ item.gold_weight }}
                            </td>
                            <td class="text-center">
                              {{ item.stones.length }}
                            </td>
                            <td class="text-center">
                              {{ Math.ceil(item.item_total_after_profit * optionData.dollar_price) }}
                            </td>
                            <td>
                              <v-tooltip
                                v-if="item.stones.length > 0"
                                bottom
                              >
                                <template
                                  v-slot:activator="{ on, attrs }"
                                >
                                  <v-btn
                                    x-small
                                    fab
                                    outlined
                                    class="mx-2"
                                    color="blue"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="viewStoneDialog(item)"
                                  >
                                    <v-icon>
                                      mdi-eye
                                    </v-icon>
                                  </v-btn>
                                </template>
                                {{ $t('actions.viewStones') }}
                              </v-tooltip>
                              <v-tooltip
                                bottom
                              >
                                <template
                                  v-slot:activator="{ on, attrs }"
                                >
                                  <v-btn
                                    x-small
                                    fab
                                    outlined
                                    class="mx-2"
                                    color="red"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="confirmDeleteItem(item)"
                                  >
                                    <v-icon>
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>
                                </template>
                                {{ $t('actions.delete') }}
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table> -->
                  </v-card>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-col
                  cols="12 d-flex"
                >
                  <v-autocomplete
                    v-model="data.client_id"
                    :items="items"
                    :loading="isLoading"
                    :search-input.sync="search"
                    hide-no-data
                    hide-selected
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    :rules="globalRules"
                    :label="$t('filter.clients')"
                    :placeholder="$t('filter.startTypingToSearch')"
                  />
                  <v-btn
                    outlined
                    success
                    class="addClientBtn"
                    @click="addClientDialog = true"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-left="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="data.date"
                        :label="$t('stock.date')"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        dense
                        outlined
                        :rules="globalRules"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="data.date"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="data.branch_id"
                    :items="branches"
                    item-text="name"
                    item-value="id"
                    dense
                    :label="$t('filter.branch')"
                    outlined
                    required
                    :rules="globalRules"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="data.employee_id"
                    :items="employees"
                    hide-no-data
                    hide-selected
                    outlined
                    dense
                    item-text="name"
                    item-value="id"
                    :rules="globalRules"
                    :label="$t('filter.employee')"
                    :placeholder="$t('filter.startTypingToSearch')"
                  />
                </v-col>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col
                    md="4"
                  >
                    <v-text-field
                      v-model="data.total_egp"
                      required
                      dense
                      outlined
                      :rules="globalRules"
                    />
                    <p>
                      {{ 'total EGP = ' + (Math.ceil(data.products.reduce(
                        (p, i) => p + +i.item_total_after_profit,
                        0
                      ) * optionData.dollar_price)) }}
                    </p>
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="data.paid_egp"
                      required
                      dense
                      outlined
                      :rules="globalRules"
                    />
                    <b>{{ 'Remaining Amount ' + (data.total_egp - data.paid_egp) + ' EGP' }}</b>
                  </v-col>
                  <v-col
                    md="4"
                  >
                    <v-select
                      v-model="data.payment_method"
                      :items="payment_method"
                      :label="$t('filter.paymentMethod')"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :rules="globalRules"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              class="mx-auto my-auto d-flex"
              color="indigo"
              :loading="loading"
              :disabled="disabled"
              @click="submitForm()"
            >
              {{ this.$route.name === 'salesForm' ? $t('actions.makeSale') : $t('actions.return') }}
            </v-btn>
          </v-container>
        </v-form>
      </template>
    </v-card>
    <v-dialog
      v-model="deleteDailog"
      width="500"
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          :title="$t('actions.deleteConfirmation')"
          color="error"
          class="pt-12"
        >
          <v-card-text class="mt-2">
            {{ $t('actions.areYouSureDelete') + ' ' + itemDetails.label + ' ' + $t('actions.?') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green"
              outlined
              :loading="loading"
              :disabled="disabled"
              @click="deleteItem(itemDetails)"
            >
              {{ $t('actions.delete') }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              @click="deleteDailog = false"
            >
              {{ $t('actions.close') }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="stoneDailog"
      width="800"
      persistent
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          :title="$t('actions.stoneDetails')"
          color="success"
          class="pt-12"
        >
          <v-card-text class="mt-2">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Label
                    </th>
                    <th class="text-left">
                      Qty
                    </th>
                    <th class="text-left">
                      Weight
                    </th>
                    <!-- <th class="text-left">
                      Setting
                    </th> -->
                    <th class="text-left">
                      Color
                    </th>
                    <th class="text-left">
                      Clarity
                    </th>
                    <th class="text-left">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="( item) in itemDetails.stones"
                    :key="item.id"
                  >
                    <td>
                      {{ item.code }}
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.quantity"
                        required
                        dense
                        outlined
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.weight"
                        required
                        dense
                        outlined
                      />
                    </td>
                    <!-- <td>
                      <v-text-field
                        v-model="item.setting"
                        required
                        dense
                        outlined
                      />
                    </td> -->
                    <td>
                      <v-text-field
                        v-model="item.color"
                        required
                        dense
                        outlined
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="item.clarity"
                        required
                        dense
                        outlined
                      />
                    </td>
                    <td>
                      {{ item.total = (+item.price * +item.weight) + (+item.quantity) }}
                      <!-- * +item.setting -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              outlined
              @click="saveStoneChanges(itemDetails)"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addClientDialog"
      width="1200"
    >
      <v-card
        class="text-center"
      >
        <base-material-card
          :title="$t('clients.client.addClient')"
          color="success"
          class="pt-12"
        >
          <v-card-text class="mt-2">
            <clientForm />
          </v-card-text>
        </base-material-card>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      absolute
      bottom
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import clientForm from '../clients/clients/Form.vue'
  import { ServiceFactory } from '@/services/ServiceFactory'
  const invoicesService = ServiceFactory.get('invoices')
  const branchesService = ServiceFactory.get('branches')
  const clientsService = ServiceFactory.get('clients')
  const mainStockServices = ServiceFactory.get('mainStock')
  const optionsService = ServiceFactory.get('options')
  const employeeServices = ServiceFactory.get('employee')

  export default {
    name: 'SalesForm',
    components: {
      clientForm,
    },
    data: (vm) => ({
      descriptionLimit: 30,
      search: null,
      productSearch: null,
      isLoading: false,
      productLoading: false,
      entries: [],
      model: null,
      menu: false,
      deleteDailog: false,
      addClientDialog: false,
      stoneDailog: false,
      dataLoading: false,
      valid: false,
      editedIndex: -1,
      data: {
        branch: null,
        client: null,
        date: null,
        product_id: null,
        products: [],
        payment_method: 1,
        paid_egp: 0,
        total_dollar: 0,
        total_egp: 0,
        employee_id: null,
      },
      itemDetails: {},
      optionData: {},
      choosenProduct: null,
      products: [],
      branches: [],
      employees: [],
      payment_method: [
        {
          id: 1,
          name: 'Cash',
        }, {
          id: 2,
          name: 'Visa',
        }, {
          id: 3,
          name: 'Bank Check',
        },
      ],
      successSnackbar: false,
      errorSnackbar: false,
      timeout: 3000,
      successMessage: '',
      errorMessage: '',
      loading: false,
      disabled: false,
      globalRules: [
        v => !!v || 'data is required',
      ],
      expanded: [],
      singleExpand: false,
      productsHeaders: [
        {
          text: 'Image',
          align: 'start',
          sortable: false,
          value: 'image',
        },
        { text: 'Product Code', value: 'label' },
        { text: 'Weight', value: 'gold_weight' },
        { text: 'QTY', value: 'stones' },
        { text: 'Total in EGP', value: 'item_total_after_profit' },
        { text: 'Actions', value: 'actions' },
      ],
    }),
    computed: {
      items () {
        console.log('this.entries', this.entries)
        return this.entries.map(entry => {
          const name = entry.name.length > this.descriptionLimit
            ? entry.name.slice(0, this.descriptionLimit) + '...'
            : entry.name
          return Object.assign({}, entry, { name })
        })
      },

    },

    watch: {
      async search (val) {
        // Items have already been loaded
        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        const listData = await clientsService.getActiveList()
        this.entries = listData
        this.count = listData.length
        this.isLoading = false
      },
    },
    created () {
      this.getAllBranches()
      this.fetchOptionData()
      this.getAllEmployees()
      if (this.$route.params.id) {
        this.fetchItem(this.$route.params.id)
      }
      console.log('this.$route.params.id', this.$route)
    },
    methods: {
      saveStoneChanges (itemDetails) {
        console.log('changes', itemDetails)
        this.data.products.find(i => {
          if (this.itemDetails.id === i.id) {
            const item = this.data.products.indexOf(itemDetails)
            this.data.products.splice(item, 1)
            this.data.products.push(itemDetails)
            // this.stoneData = this.stoneDetails
            console.log('this.data.products', this.data.products)
            this.stoneDailog = false
          }
        })
      },
      addItem (choosenProduct) {
        this.data.products.push(choosenProduct)
        console.log('this.data.products', this.data.products)
      },
      confirmDeleteItem (data) {
        this.itemDetails = data
        this.deleteDailog = true
      },
      async deleteItem (itemDetails) {
        this.loading = true
        this.disabled = true
        this.deleteDailog = false
        this.successMessage = 'Item Is Deleted Successfuly'
        this.successSnackbar = true
        setTimeout(() => {
          this.editedIndex = this.data.products.indexOf(itemDetails)
          this.data.products.splice(this.editedIndex, 1)
        }, 500)
        this.disabled = false
        this.loading = false
      },
      viewStoneDialog (data) {
        this.itemDetails = {}
        this.itemDetails = data
        this.stoneDailog = true
        console.log('this.itemDetails', this.itemDetails)
      },
      async  submitForm () {
        if (this.$refs.form.validate() === true) {
          this.loading = true
          this.disabled = true
          console.log('this.itemDetails', this.data)
          const formData = {
            branch_id: this.data.branch_id,
            client_id: this.data.client_id,
            dollar_price: this.optionData.dollar_price,
            items: this.data.products,
            paid_egp: this.data.paid_egp,
            payment_method: this.data.payment_method,
            receipt_date: this.data.date,
            total_dollar: this.data.products.reduce(
              (p, i) => p + +i.item_total_after_profit,
              0
            ),
            total_egp: this.data.total_egp,
            employee_id: this.data.employee_id,
          }
          console.log('formData', formData)
          if (this.$route.name === 'salesForm') {
            this.newItem(formData)
          } else {
            this.refund(
              {
                branch_id: this.data.branch_id,
                client_id: this.data.client_id,
                dollar_price: this.optionData.dollar_price,
                items: this.data.products,
                paid_egp: +this.data.paid_egp,
                payment_method: this.data.payment_method,
                product_id: this.data.products[0].id,
                total_dollar: this.data.products.reduce(
                  (p, i) => p + +i.item_total_after_profit,
                  0
                ),
                total_egp: this.data.total_egp,
                employee_id: this.data.employee_id,
              }
            )
          }
        }
      },
      async newItem (data) {
        const item = await invoicesService.AddNewItem(data)
        try {
          if (item.status === 'success') {
            this.successMessage = 'invoice Added Successfuly'
            this.successSnackbar = true
            setTimeout(() => {
              this.$router.push('/sales/list')
            }, 1500)
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
        }
        this.disabled = false
        this.loading = false
      },
      async refund (data) {
        const item = await invoicesService.refund(data)
        try {
          if (item.status === 'success') {
            this.successMessage = 'invoice returned Successfuly'
            this.successSnackbar = true
            setTimeout(() => {
              this.$router.push('/sales/list')
            }, 1500)
          } else {
            this.errorMessage('something Error')
            this.errorSnackbar = true
          }
        } catch (err) {
          console.log('err', err)
          this.errorMessage('something Error')
          this.errorSnackbar = true
        }
        this.disabled = false
        this.loading = false
      },
      async fetchItem (id) {
        this.dataLoading = true
        const item = await mainStockServices.getProductById(id)
        console.log('item', item)
        this.data.products.push(item.data)
        // this.branches = item.data
        this.dataLoading = false
      },
      async getAllBranches () {
        this.dataLoading = true
        const items = await branchesService.getActiveList()
        this.branches = items.data
        this.dataLoading = false
      },
      getProducts (event) {
        setTimeout(async () => {
          this.productLoading = true
          const value = event.target.value
          // getSoldProducts
          if (this.$route.name === 'salesForm') {
            const listData = await mainStockServices.getActivatedProducts(value)
            this.products = listData.data
          } else {
            const listData = await mainStockServices.getSoldProducts(value)
            this.products = listData.data
          }
          this.productLoading = false
        }, 700)
      },
      async fetchOptionData () {
        this.dataLoading = true
        const item = await optionsService.fetchData()
        this.optionData = item[0]
        this.dataLoading = false
      },
      async getAllEmployees () {
        this.dataLoading = true
        const list = await employeeServices.getAllItems(20, 1)
        console.log('list', list)
        this.employees = list.data
        this.dataLoading = false
      },
    },
  }
</script>
<style>
a{
  text-decoration: none;
}
.addClientBtn{
  color: #4caf50 !important;
  width: 40px !important;
  padding: 12px !important;
}
</style>
